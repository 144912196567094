module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"default":{"colors":{"background":"#fff"},"styles":{"h1":{"fontWeight":"400","lineHeight":"2.25rem","fontSize":"1.75rem","letterSpacing":"-0.2px"},"h2":{"textTransform":"uppercase","fontWeight":"700","lineHeight":"1rem","fontSize":"0.75rem","letterSpacing":"0.15px","color":"#5E6678"},"h3":{"fontSize":"1.125rem","fontWeight":"500","lineHeight":"1.375rem","letterSpacing":"0px"},"h4":{"fontSize":"0.75rem","fontWeight":"400","lineHeight":"1rem","color":"#5E6678"},"h5":{"fontSize":"0.875rem","fontWeight":"400","lineHeight":"1.25rem","textAlign":"left","color":"#5E6678"},"hr":{"color":"#B5B9BF","borderWidth":"0.03rem"}}}}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
